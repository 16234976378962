import React from "react";
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Grid, Paper, ButtonBase } from "@material-ui/core";
import { CarMake } from "leasemojo-common";

import CarLogo from '../CarImage/CarLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(4, 0),
        maxWidth: 800,
        margin: '0 auto',
      },
      grid: {
        width: '100%',
        margin: 0,
      },
      item: {
        display: 'flex',
        justifyContent: 'center',
      },
      card: {
        overflow: 'hidden',
      },
      button: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 140,
        padding: theme.spacing(2, 0),
      }
    }
  ),
);

interface Props {
  title?: string;
  cars: CarMake[],
  onClick?: (index: number, car: CarMake) => void;
  itemUrl?: string;
}

const CarGrid: React.FC<Props> = ({ cars, title = 'Browse by Brand', onClick, itemUrl }) => {
  const classes = useStyles();


  const onCarClick = (index: number) => {
    onClick && onClick(index, cars[index]);
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" align="center" paragraph>{title}</Typography>
      <Grid container spacing={2} className={classes.grid}>
        {
          cars.map((car, index) => (
            <Grid
              item
              key={car.name}
              xs={6}
              sm={4}
              md={3}
              className={classes.item}
            >
              <Paper elevation={0} className={classes.card}>
                <ButtonBase
                  className={classes.button}
                  component={itemUrl ? Link : 'div'}
                  to={itemUrl ? itemUrl.replace('{id}', car.slug) : undefined}
                  onClick={!onClick ? undefined : () => {
                    onCarClick(index);
                  }}>
                  <CarLogo car={car} />
                  <Typography variant="body2">{car.name}</Typography>
                </ButtonBase>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default CarGrid;