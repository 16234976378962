import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
} from "@material-ui/core";
import { graphql } from "gatsby";
import { CarMake } from "leasemojo-common";

import Layout from '../components/Layout';
import CarGrid from "../components/CarGrid/CarGrid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        flexWrap: 'wrap',
        // flexDirection: 'column',
        // alignItems: 'center',
      },
      carLogo: {
        marginRight: theme.spacing(3),
      }
    }
  ),
);

interface Props {
  data: {
    allCar: {
      nodes: CarMake[]
    }
  }
}

const ListCars: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.root} title="Car Specs">
      <CarGrid cars={data.allCar.nodes} itemUrl={'/cars/{id}'} title="Car Specs"/>
    </Layout>
  );
};

export default ListCars;

export const query = graphql`
  query {
    allCar(sort: {fields: name, order: ASC}) {
      nodes {
        id
        logoUrl
        name
        slug
        viprMakeId
      }
    }
  }
`
